import React from "react"
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'leaflet/dist/leaflet.css';


export default class ContactPage extends React.Component {
    constructor(props) {
        super(props)

    }


    render() {
        return (
            <Layout>
                <SEO title="Anfahrt"/>

                <section className="page-section" id="services">
                    <div className="container">
                        <h1 className="text-center mt-0">Anfahrt</h1>
                        <hr className="divider my-4"/>
                        <div className="row text-center">
                            <a className={'btn btn-primary'}
                               href={"http://maps.apple.com/?daddr=Bei+der+Kirche+3,+23749+Grube&dirflg=d&t=m"}
                               target={"_blank"}><i className={'fa fa-route'}/> Routenplaner</a>
                            {(typeof window !== 'undefined') &&
                            <>
                                <hr/>
                                <MapContainer style={{height: 400}} center={[54.23555009094411, 11.034139185441315]}
                                              zoom={14} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker  position={[54.23555009094411, 11.034139185441315]}>
                                        <Popup>
                                            Dr. med Elke Natascha Heinitz
                                            <br/>
                                            Bei der Kirche 3
                                            <br/>
                                            23749 Grube
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </>
                            }
                        </div>
                    </div>
                </section>


            </Layout>
        )
    }
}

